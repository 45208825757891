var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm1" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "오더내역" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-appr-btn", {
                              ref: "appr-btn",
                              attrs: {
                                name: "equipment-daily-appr-btn",
                                apprRequestTitle: "정비오더 승인요청",
                                editable: _vm.editable,
                                approvalInfo: _vm.approvalInfo,
                              },
                              on: {
                                beforeApprAction: _vm.saveDataAppr,
                                callbackApprAction: _vm.approvalCallback,
                                requestAfterAction: _vm.getDetail,
                              },
                            }),
                            !_vm.param.workPlanId && _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "이전 정비오더 불러오기",
                                    icon: "upload_file",
                                  },
                                  on: { btnClicked: _vm.copyData },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.param.workPlanId &&
                            _vm.param.woWorkPlanStepCd == "WWPSC99998" &&
                            !_vm.disabled
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "삭제",
                                    icon: "delete_forever",
                                  },
                                  on: { btnClicked: _vm.removePlan },
                                })
                              : _vm._e(),
                            _vm.editable && !_vm.disabled
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "정비오더No.",
                              name: "workOrderNo",
                            },
                            model: {
                              value: _vm.data.workOrderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workOrderNo", $$v)
                              },
                              expression: "data.workOrderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "정비오더명",
                              name: "workPlanName",
                            },
                            model: {
                              value: _vm.data.workPlanName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workPlanName", $$v)
                              },
                              expression: "data.workPlanName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              codeGroupCd: "WO_WORK_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "woWorkTypeCd",
                              label: "정비유형",
                            },
                            model: {
                              value: _vm.data.woWorkTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woWorkTypeCd", $$v)
                              },
                              expression: "data.woWorkTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-equip", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "대표설비Tag",
                              name: "equipmentCd",
                            },
                            on: { dataChange: _vm.equipChange },
                            model: {
                              value: _vm.data.equipmentCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentCd", $$v)
                              },
                              expression: "data.equipmentCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-func-location", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: _vm.editable && !_vm.disabled,
                              label: "기능위치",
                              name: "funcLocationCd",
                            },
                            model: {
                              value: _vm.data.funcLocationCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "funcLocationCd", $$v)
                              },
                              expression: "data.funcLocationCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "정비오더 발행부서",
                              name: "workDeptCd",
                            },
                            model: {
                              value: _vm.data.workDeptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workDeptCd", $$v)
                              },
                              expression: "data.workDeptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              data: _vm.data,
                              type: "user",
                              label: "작업계획자",
                              name: "planUserId",
                            },
                            model: {
                              value: _vm.data.planUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "planUserId", $$v)
                              },
                              expression: "data.planUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.data.reqDeptCd
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                            },
                            [
                              _c("c-field", {
                                attrs: {
                                  editable: false,
                                  type: "dept_user",
                                  data: _vm.data,
                                  deptValue: "reqDeptCd",
                                  label: "정비요청자",
                                  name: "actionCompleteCheckUserId",
                                },
                                model: {
                                  value: _vm.data.reqUserId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "reqUserId", $$v)
                                  },
                                  expression: "data.reqUserId",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-work-order", {
                            attrs: {
                              editable: _vm.editable && !_vm.disabled,
                              label: "선행 정비오더",
                              name: "preWorkPlanId",
                            },
                            model: {
                              value: _vm.data.preWorkPlanId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "preWorkPlanId", $$v)
                              },
                              expression: "data.preWorkPlanId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-dept-multi", {
                            attrs: {
                              required: "",
                              isArray: false,
                              editable: _vm.editable && !_vm.disabled,
                              label: "정비작업팀",
                              name: "workDepts",
                            },
                            model: {
                              value: _vm.data.workDepts,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workDepts", $$v)
                              },
                              expression: "data.workDepts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5",
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                              },
                              [
                                _c("c-datepicker", {
                                  attrs: {
                                    required: true,
                                    range: true,
                                    editable: _vm.editable && !_vm.disabled,
                                    type: "date",
                                    label: "작업계획기간",
                                    name: "planDts",
                                  },
                                  model: {
                                    value: _vm.data.planDts,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "planDts", $$v)
                                    },
                                    expression: "data.planDts",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                              },
                              [
                                _c("c-textarea", {
                                  attrs: {
                                    editable: _vm.editable && !_vm.disabled,
                                    rows: 4,
                                    label: "정비요청 세부사항",
                                    name: "remark",
                                  },
                                  model: {
                                    value: _vm.data.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "remark", $$v)
                                    },
                                    expression: "data.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c(
                            "c-table",
                            {
                              ref: "gridCost",
                              attrs: {
                                title: "비용정산 규칙",
                                columns: _vm.gridCost.columns,
                                data: _vm.data.costList,
                                gridHeight: _vm.gridCost.height,
                                editable: _vm.editable && !_vm.disabled,
                                hideBottom: true,
                                isExcelDown: false,
                                filtering: false,
                                isFullScreen: false,
                                columnSetting: false,
                                selection: "multiple",
                                rowKey: "costCenterCd",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "table-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _vm.editable && !_vm.disabled
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "추가",
                                              icon: "add",
                                            },
                                            on: { btnClicked: _vm.addRowCost },
                                          })
                                        : _vm._e(),
                                      _vm.editable && !_vm.disabled
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "삭제",
                                              icon: "remove",
                                            },
                                            on: {
                                              btnClicked: _vm.removeRowCost,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c(
                            "c-table",
                            {
                              ref: "grid0",
                              attrs: {
                                title: "관련설비",
                                columns: _vm.grid0.columns,
                                data: _vm.grid0.data,
                                gridHeight: _vm.grid0.height,
                                editable: _vm.editable && !_vm.disabled,
                                hideBottom: true,
                                isExcelDown: false,
                                filtering: false,
                                isFullScreen: false,
                                columnSetting: false,
                                selection: "multiple",
                                rowKey: "equipmentCd",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "table-button" },
                                [
                                  _c(
                                    "q-btn-group",
                                    { attrs: { outline: "" } },
                                    [
                                      _vm.editable &&
                                      !_vm.disabled &&
                                      Boolean(_vm.param.workPlanId)
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "추가",
                                              icon: "add",
                                            },
                                            on: { btnClicked: _vm.addRowEquip },
                                          })
                                        : _vm._e(),
                                      _vm.editable &&
                                      !_vm.disabled &&
                                      Boolean(_vm.param.workPlanId)
                                        ? _c("c-btn", {
                                            attrs: {
                                              label: "삭제",
                                              icon: "remove",
                                            },
                                            on: {
                                              btnClicked: _vm.removeRowEquip,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("c-tab", {
              attrs: {
                type: "tabcard",
                tabItems: _vm.data.workList,
                addTab: _vm.editable && !_vm.disabled,
                removeTab: _vm.editable && !_vm.disabled,
                inlineLabel: true,
                isTabTitle: true,
                tabTitle: "작업계획",
                align: "left",
              },
              on: {
                "update:tabItems": function ($event) {
                  return _vm.$set(_vm.data, "workList", $event)
                },
                "update:tab-items": function ($event) {
                  return _vm.$set(_vm.data, "workList", $event)
                },
                addTab: _vm.addTab,
                removeTab: _vm.removeTab,
                tabClick: _vm.tabClick,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (tab) {
                    return [
                      _c(tab.component, {
                        key: tab.key,
                        tag: "component",
                        attrs: {
                          param: _vm.param,
                          equips: _vm.grid0.data,
                          selectedWork: _vm.selectedWork,
                          workdata: _vm.workdata,
                          disabledAppr: _vm.disabledAppr,
                          disabledObj: _vm.disabledObj,
                        },
                        on: {
                          "update:param": function ($event) {
                            _vm.param = $event
                          },
                          "update:equips": function ($event) {
                            return _vm.$set(_vm.grid0, "data", $event)
                          },
                          "update:selectedWork": function ($event) {
                            _vm.selectedWork = $event
                          },
                          "update:selected-work": function ($event) {
                            _vm.selectedWork = $event
                          },
                          "update:workdata": function ($event) {
                            _vm.workdata = $event
                          },
                          "update:disabledAppr": function ($event) {
                            _vm.disabledAppr = $event
                          },
                          "update:disabled-appr": function ($event) {
                            _vm.disabledAppr = $event
                          },
                          "update:disabledObj": function ($event) {
                            _vm.disabledObj = $event
                          },
                          "update:disabled-obj": function ($event) {
                            _vm.disabledObj = $event
                          },
                          saveTab: _vm.saveTab,
                        },
                      }),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            }),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }