<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm1">
          <c-card class="cardClassDetailForm" title="오더내역">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  ref="appr-btn"
                  name="equipment-daily-appr-btn"
                  apprRequestTitle="정비오더 승인요청"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @requestAfterAction="getDetail"
                />
                <c-btn v-if="!param.workPlanId && editable" label="이전 정비오더 불러오기" icon="upload_file" @btnClicked="copyData" />
                <c-btn label="삭제" v-if="editable && param.workPlanId && param.woWorkPlanStepCd=='WWPSC99998' && !disabled" icon="delete_forever" @btnClicked="removePlan" />
                <c-btn
                  v-if="editable &&!disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="false"
                  label="정비오더No."
                  name="workOrderNo"
                  v-model="data.workOrderNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable&&!disabled"
                  label="정비오더명"
                  name="workPlanName"
                  v-model="data.workPlanName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable&&!disabled"
                  codeGroupCd="WO_WORK_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="woWorkTypeCd"
                  label="정비유형"
                  v-model="data.woWorkTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  :required="true"
                  :editable="editable&&!disabled"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-equip
                  :required="true"
                  :editable="editable&&!disabled"
                  label="대표설비Tag"
                  name="equipmentCd"
                  v-model="data.equipmentCd"
                  @dataChange="equipChange">
                </c-equip>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-func-location 
                  type="edit" 
                  :plantCd="data.plantCd" 
                  :editable="editable&&!disabled" 
                  label="기능위치" 
                  name="funcLocationCd" 
                  v-model="data.funcLocationCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-dept type="edit" :required="true" :editable="editable&&!disabled" label="정비오더 발행부서" name="workDeptCd" v-model="data.workDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :required="true"
                  :editable="editable&&!disabled"
                  :data="data"
                  type="user"
                  label="작업계획자"
                  name="planUserId"
                  v-model="data.planUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" v-if="data.reqDeptCd">
                <c-field 
                  :editable="false"
                  type="dept_user" 
                  :data="data"
                  deptValue="reqDeptCd"
                  label="정비요청자" 
                  name="actionCompleteCheckUserId" 
                  v-model="data.reqUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-work-order
                  :editable="editable&&!disabled"
                  label="선행 정비오더" 
                  name="preWorkPlanId" 
                  v-model="data.preWorkPlanId"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-dept-multi
                  required
                  :isArray="false"
                  :editable="editable&&!disabled"
                  label="정비작업팀" 
                  name="workDepts" 
                  v-model="data.workDepts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <c-datepicker
                      :required="true"
                      :range="true"
                      :editable="editable&&!disabled"
                      type="date"
                      label="작업계획기간"
                      name="planDts"
                      v-model="data.planDts"
                    />
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <c-textarea
                      :editable="editable&&!disabled"
                      :rows="4"
                      label="정비요청 세부사항"
                      name="remark"
                      v-model="data.remark">
                    </c-textarea>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-table
                  ref="gridCost"
                  title="비용정산 규칙"
                  :columns="gridCost.columns"
                  :data="data.costList"
                  :gridHeight="gridCost.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="costCenterCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowCost" v-if="editable&&!disabled" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRowCost" v-if="editable&&!disabled" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-table
                  ref="grid0"
                  title="관련설비"
                  :columns="grid0.columns"
                  :data="grid0.data"
                  :gridHeight="grid0.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="equipmentCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRowEquip" v-if="editable&&!disabled && Boolean(param.workPlanId)" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRowEquip" v-if="editable&&!disabled && Boolean(param.workPlanId)" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-tab
          type="tabcard"
          :tabItems.sync="data.workList"
          :addTab="editable && !disabled"
          :removeTab="editable && !disabled"
          :inlineLabel="true"
          v-model="tab"
          :isTabTitle="true"
          tabTitle="작업계획"
          align="left"
          @addTab="addTab"
          @removeTab="removeTab"
          @tabClick="tabClick"
        >
          <template v-slot:default="tab">
            <component
              :is="tab.component"
              :key="tab.key"
              :param.sync="param"
              :equips.sync="grid0.data"
              :selectedWork.sync="selectedWork"
              :workdata.sync="workdata"
              :disabledAppr.sync="disabledAppr"
              :disabledObj.sync="disabledObj"
              @saveTab="saveTab"
            />
          </template>
        </c-tab>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-plan-direction',
  props: {
    param: {
      type: Object,
      default: () => ({
        newEquipmentCd: '',
        newTitle: '',
        newWoWorkTypeCd: '', // 작업유형 (PM)
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        dailyCheckId: '',
        minEquipmentCheckId: '',
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'empty',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      selectedWork: false,
      workerSwss: [],
      workerSwssInfo: '',
      editable: true,
      detailUrl: '',
      deleteUrl: '',
      removeUrl: '',
      insertUrl: '',
      updateUrl: '',
      equiplistUrl: '',
      insertequipUrl: '',
      removeequipUrl: '',
      costlistUrl: '',
      insertcostUrl: '',
      removecostUrl: '',
      worklistUrl: '',
      workgetUrl: '',
      saveWorkUrl: transactionConfig.wod.workorder.plan.work.insert.url,
      removeworkUrl: '',
      isApproval: false,
      saveUrl: transactionConfig.wod.workorder.plan.update.url,
      mappingType: 'PUT',
      mappingType2: 'PUT',
      isSave: false,
      isSave2: false,
      data: {
        plantCd: null,  // 사업장코드
        preWorkPlanId: '',  // 작업계획 일련번호
        workPlanId: '',  // 작업계획 일련번호
        workOrderNo: '',  // 작업지시번호
        workPlanName: '',  // 작업명
        workDeptCd: '',  // 작업부서
        planUserId: '',  // 작업지시자
        woRequestId: '',  // 정비요청 일련번호
        reqDeptCd: '',  // 요청부서
        reqUserId: '',  // 요청인
        reqDt: '',  // 요청서 발행일
        woWorkTypeCd: null,  // 작업 유형코드
        equipmentCd: '',  // 대상 설비
        funcLocationCd: '',  // 기능위치
        remark: '',  // 정비요청 세부사항
        swpFlag: 'N',  // 안전작업허가서 발행여부
        woWorkPlanStepCd: '',  // 작업진행 상태
        preWoWorkPlanStepCd: '',
        planDt: '',
        planDts: [],
        planStartDt: '',  // 목표 시작일
        planStartTime: '',  // 목표 시작시간
        planStartDts: '',
        planEndDt: '',  // 목표 완료일
        planEndTime: '',  // 목표 종료시간
        planEndDts: '',
        mdmSwsId: '',  // 안전작업 표준 일련번호
        chgUserName: '',
        chgDtStr: '',
        workDepts: '',
        costList: [],
        workList: [],
      },
      workdata: {
        workPlanId: '',  // 정비오더 일련번호
        workPlanWorkId: '',  // 작업계획 일련번호
        workPlanWorkName: '',  // 작업내용
        sortOrder: '',  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workStartDt: '',  // 작업 시작일
        workEndDt: '',  // 작업 종료일
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        workDeptCd: '',  // 작업부서
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        workHolidayFlag: 'N',
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      grid0: {
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비Tag',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'equipmentNum',
            field: 'equipmentNum',
            label: '설비관리번호',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'center',
            sortable: false,
          },
          // {
          //   name: 'funcLocationName',
          //   field: 'funcLocationName',
          //   label: '기능위치',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '170px',
      },
      gridCost: {
        columns: [
          {
            name: 'costCenterNo',
            field: 'costCenterNo',
            label: '코스트센터 번호',
            align: 'center',
            style: 'width:110px',
            sortable: false,
          },
          {
            name: 'costCenterName',
            field: 'costCenterName',
            label: '코스트센터 명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'calculatePercent',
            field: 'calculatePercent',
            label: '정산율(%)',
            align: 'center',
            type: 'number',
            style: 'width:90px',
            sortable: false,
          },
        ],
        data: [],
        height: '170px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      this.$emit('disabledSet', this.disabledObj.disabled)
      return this.disabledObj.disabled;
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.woWorkPlanStepCd && this.data.woWorkPlanStepCd !== 'WWPSC00009' && this.data.woWorkPlanStepCd !== 'WWPSC99999' && this.data.woWorkPlanStepCd !== 'WWPSC99998' && !this.disabledAppr, // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000005', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          workPlanId: this.param.workPlanId
        },
        approvalRequestName: '정비오더 ['+this.data.workPlanName+']', // 결재요청명 (문서 title)
        approvalConnId: this.param.workPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.wod.workorder.plan.get.url;
      this.insertUrl = transactionConfig.wod.workorder.plan.insert.url;
      this.updateUrl = transactionConfig.wod.workorder.plan.update.url;
      this.deleteUrl = transactionConfig.wod.workorder.plan.cancel.url;
      this.removeUrl = transactionConfig.wod.workorder.plan.delete.url;
      
      this.removeworkUrl = transactionConfig.wod.workorder.plan.work.delete.url;

      this.equiplistUrl = selectConfig.wod.workorder.plan.equip.url;
      this.insertequipUrl = transactionConfig.wod.workorder.plan.equip.insert.url;
      this.removeequipUrl = transactionConfig.wod.workorder.plan.equip.delete.url;

      this.costlistUrl = selectConfig.wod.workorder.plan.cost.url;
      this.insertcostUrl = transactionConfig.wod.workorder.plan.cost.save.url;
      this.removecostUrl = transactionConfig.wod.workorder.plan.cost.delete.url;

      if (this.param.woWorkPlanStepCd !== 'WWPSC00001') {
        this.disabledObj.disabled = true;
      }
      this.getDetail();
    },
    getDetail() {
      if (this.param.workPlanId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {workPlanId: this.param.workPlanId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          console.log(_result.data)
          this.data = _result.data;
          if (this.data.woWorkPlanStepCd !== 'WWPSC00001') {
            this.disabledObj.disabled = true;
          } else {
            this.disabledObj.disabled = false;
          }
          if (this.data.costCenterCd && this.data.costList.length == 0) {
            this.data.costList.push({
              workPlanId: this.param.workPlanId,  // 작업계획 일련번호
              costCenterCd: this.data.costCenterCd,  // 코스트센터 코드
              costCenterNo: this.data.costCenterNo,  // 코스트센터 코드
              costCenterName: this.data.costCenterName,  // 코스트센터 코드
              calculatePercent: 100,  // 정산율
              calculateCost: '',  // 정산금액
              remark: '',  // 비고
              editFlag: 'C',
            })
          }
          if (!this.data.planUserId) {
            this.data.planUserId = this.$store.getters.user.userId;
          }
          if (this.data.workList && this.data.workList.length > 0) {
            this.$_.forEach(this.data.workList, _item => {
              _item.label = `${_item.workPlanWorkName}[${_item.workOprTypeName}]<br/>${_item.workDtsStr}`
              _item.key = uid();
              _item.name = _item.workPlanWorkId;
              _item.component =  () => import(`${'./workOrderPlanService.vue'}`)
              // _item.icon = 'list_alt'; 
            })
          }
          this.getEquipList();
          this.selectedWork = true;
          this.setTab();
        },);
      } else {
        this.data.planUserId = this.$store.getters.user.userId;
        if (this.param.newEquipmentCd) {
          this.data.equipmentCd = this.param.newEquipmentCd;
          this.data.plantCd = this.param.plantCd;
          this.data.woWorkTypeCd = this.param.newWoWorkTypeCd;
          this.data.workPlanName = this.param.newTitle;
        }
      }
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveDataAppr() {
      let totalPercent = 0;
      this.$_.forEach(this.data.costList, _item => {
        totalPercent += parseInt(_item.calculatePercent)
      });
      if (totalPercent != 100) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '정산율의 총 합이 100이어야 합니다.', /* 작업계획취소가 처리되었습니다. */
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$refs['editForm1'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;
                let saveDataCost = this.data.costList.filter( x => {
                  return x.editFlag == 'C' || x.editFlag == 'U'
                });
                this.$_.forEach(saveDataCost, item => {
                  item.regUserId = this.$store.getters.user.userId;
                  item.chgUserId = this.$store.getters.user.userId;
                });
                this.data.costList = saveDataCost;
                
                let promises = [
                  {
                    func: this.approvalValue
                  },
                ];
                this.$comm.orderedPromise(promises);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
      
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    saveData() {
      if (this.param.workPlanId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
        this.data.woWorkPlanStepCd = 'WWPSC00001';
        this.data.dailyCheckId = this.param.dailyCheckId;
        this.data.minEquipmentCheckId = this.param.minEquipmentCheckId;
      }
      let totalPercent = 0;
      this.$_.forEach(this.data.costList, _item => {
        totalPercent += parseInt(_item.calculatePercent)
      });
      if (totalPercent != 100) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '정산율의 총 합이 100이어야 합니다.', /* 작업계획취소가 처리되었습니다. */
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$refs['editForm1'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;
                if (this.mappingType == 'POST') {
                  this.data.equipList = this.grid0.data;
                }

                let saveDataCost = this.data.costList.filter( x => {
                  return x.editFlag == 'C' || x.editFlag == 'U'
                });
                this.$_.forEach(saveDataCost, item => {
                  item.regUserId = this.$store.getters.user.userId;
                  item.chgUserId = this.$store.getters.user.userId;
                });
                this.data.costList = saveDataCost;
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.workPlanId = _result.data.workPlanId;
      this.getDetail();
    },
    delPlan() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업계획을 취소하시겠습니까?',
        // TODO : 정비요청을 취소하시겠습니까?\r\n작업취소 상태로 변경됩니다.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'PUT';
          this.$http.param = {
            woWorkPlanStepCd: 'WWPSC99998', // 작업계획취소
            chgUserId: this.$store.getters.user.userId,
            workPlanId: this.param.workPlanId,
            woRequestId: this.param.woRequestId,
          };
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '작업계획취소가 처리되었습니다.', /* 작업계획취소가 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.param.woWorkPlanStepCd = 'WWPSC99998'
            this.$emit('closePopup')
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업계획의 모든 정보가 삭제됩니다.\r\n작업계획을 삭제하시겠습니까?',
        // TODO : 작업계획의 모든 정보가 삭제됩니다.\r\n작업계획을 삭제하시겠습니까?
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.removeUrl, this.param.workPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '작업계획이 삭제되었습니다.', /* 작업계획취소가 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.$emit('closePopup')
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addRowEquip() {
      this.popupOptions.title = '설비 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowEquip;
    },
    closeaddRowEquip(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      let _saveData = [];
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid0.data, { equipmentCd: item.equipmentCd }) === -1) {
            _saveData.push({
              workPlanId: this.param.workPlanId,  // 작업지시번호
              equipmentNo: item.equipmentNo,  // 작업자 사번
              equipmentNum: item.equipmentNum,
              equipmentCd: item.equipmentCd,
              equipmentName: item.equipmentName,
              funcLocationName: item.funcLocationName,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              mainEquipFlag: 'N',
              editFlag: 'C',
            })
          }
        });
        this.$http.url = this.insertequipUrl;
        this.$http.type = 'POST';
        this.$http.param = _saveData;
        this.$http.request(() => {
          this.getEquipList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    getEquipList() {
      if (this.param.workPlanId) {
        this.$http.url = this.equiplistUrl;
        this.$http.param = {workPlanId: this.param.workPlanId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid0.data = _result.data;
        },);
      }
    },
    removeRowEquip() {
      let selectData = this.$refs['grid0'].selected;
      
      let filterData = this.$_.filter(selectData, item => {
        return item.mainEquipFlag === 'Y'
      })
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else if (filterData && filterData.length > 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '대표설비는 삭제할 수 없습니다.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.removeequipUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid0.data = this.$_.reject(this.grid0.data, item);
              })
              this.$refs['grid0'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveTab(key) {
      this.tab = key;
      this.getDetail();
    },
    equipChange(data) {
      if (!data) return;
      // 기능위치 변경
      console.log(data)
      if (data.funcLocationCd !== null) {
        if (this.data.funcLocationCd === null) {
          this.data.funcLocationCd = data.funcLocationCd
        } else {
          if (this.data.funcLocationCd != data.funcLocationCd) {
            this.data.funcLocationCd = data.funcLocationCd
          }
        }
      }
      if (data) {
        if (this.$_.findIndex(this.grid0.data, { equipmentCd: data.equipmentCd }) > -1) {
          this.$http.url = this.removeequipUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values([{
              workPlanId: this.param.workPlanId,  // 작업지시번호
              equipmentNo: data.equipmentNo,
              equipmentCd: data.equipmentCd,
              equipmentName: data.equipmentName,
              funcLocationName: data.funcLocationName,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
            }])
          };
          this.$http.request(() => {
            this.$refs['grid0'].$refs['compo-table'].clearSelection();
            this.saveMainEquip(data)
          },);
        } else {
          this.saveMainEquip(data);
        }
        if(this.data.costList.length == 0) {
          this.data.costList.push({
            workPlanId: this.param.workPlanId,  // 작업계획 일련번호
            costCenterCd: data.costCenterCd,  // 코스트센터 코드
            costCenterNo: data.costCenterNo,  // 코스트센터 코드
            costCenterName: data.costCenterName,  // 코스트센터 코드
            calculatePercent: 100,  // 정산율
            calculateCost: '',  // 정산금액
            remark: '',  // 비고
            editFlag: 'C',
          })
        }
      }
    },
    saveMainEquip(data) {
      if (this.param.workPlanId) {
        this.$http.url = this.insertequipUrl;
        this.$http.type = 'POST';
        this.$http.param = [{
          workPlanId: this.param.workPlanId,  // 작업지시번호
          equipmentNo: data.equipmentNo,
          equipmentCd: data.equipmentCd,
          equipmentName: data.equipmentName,
          funcLocationName: data.funcLocationName,
          regUserId: this.$store.getters.user.userId,  // 등록자 ID
          mainEquipFlag: 'Y',
          editFlag: 'C',
        }];
        this.$http.request(() => {
          this.getEquipList();
        });
      } else {
        this.grid0.data.push(
          {
            workPlanId: this.param.workPlanId,  // 작업지시번호
            equipmentNo: data.equipmentNo,
            equipmentCd: data.equipmentCd,
            equipmentName: data.equipmentName,
            funcLocationName: data.funcLocationName,
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            mainEquipFlag: 'Y',
            editFlag: 'C',
          }
        )
      }
    },
    addTab() {
      let key = uid();
      this.selectedWork = true;
      this.data.workList.push({
        workPlanId: this.param.workPlanId,  // 정비오더 일련번호
        workPlanWorkId: key,  // 작업계획 일련번호
        workPlanWorkName: '',  // 작업내용
        sortOrder: (this.data.workList.length + 1) * 10,  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workStartDt: '',  // 작업 시작일
        workEndDt: '',  // 작업 종료일
        workDts: [],  // 작업기간
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        workHolidayFlag: 'N',
        editFlag: 'C',
        name: key, 
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID

        icon: 'list_alt', 
        label: '작성중', 

        key: uid(),
        component: () => import(`${'./workOrderPlanService.vue'}`) 
      })
      this.tab = key
      this.setTab();
    },
    removeTab(tab) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까? ',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.removeworkUrl, this.workdata.workPlanWorkId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
             window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '작업계획이 삭제되었습니다.', /* 작업계획취소가 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.$set(this.data, 'workList', this.$_.reject(this.data.workList, { workPlanWorkId: tab.workPlanWorkId }))
            this.setTab();
            this.selectedWork = true;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    tabClick(tab) {
      this.tab = tab.name
      this.workdata = this.$_.find(this.data.workList, { workPlanWorkId: tab.workPlanWorkId });
    },
    setTab() {
      if (this.data.workList && this.data.workList.length > 0) {
        let idx = this.$_.findIndex(this.data.workList, { workPlanWorkId: this.tab });
        if (!this.tab || idx === -1) {
          this.tab = this.data.workList[0].workPlanWorkId
          this.workdata = this.data.workList[0];
        } else {
          this.workdata = this.data.workList[idx];
        }
      } else {
        this.tab = 'empty'
      }
    },
    addRowCost() {
      this.popupOptions.title = '코스트센터 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd,
        maintenanceFlag: '',
        purchaseFlag: '',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/cc/costCenterPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowCost;
    },
    closeaddRowCost(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.data.costList, { costCenterCd: item.costCenterCd }) === -1) {
            this.data.costList.push({
              workPlanId: this.param.workPlanId,  // 작업계획 일련번호
              costCenterCd: item.costCenterCd,  // 코스트센터 코드
              costCenterNo: item.costCenterNo,  // 코스트센터 코드
              costCenterName: item.costCenterName,  // 코스트센터 코드
              calculatePercent: (this.data.costList.length == 0 && data.length == 1 ? '100' : ''),  // 정산율
              calculateCost: '',  // 정산금액
              remark: '',  // 비고
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowCost() {
      let selectData = this.$refs['gridCost'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.removecostUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.costList = this.$_.reject(this.data.costList, item);
              })
              this.$refs['gridCost'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    copyData() {
      this.popupOptions.title = '기존 정비요청 검색'; // 정비작업표준 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.data.plantCd
      };
      this.popupOptions.target = () => import(`${'./workOrderPlanPopUp.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyPopup;
    },
    closeCopyPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.length === 1) {
        this.$http.url = this.detailUrl;
        this.$http.param = {workPlanId: data[0].workPlanId};
        this.$http.type = 'GET';
         this.$http.request((_result) => {
          this.data = _result.data;
          this.data.woWorkPlanStepCd === 'WWPSC00001'
          this.disabledObj.disabled = false;
          
          if (this.data.costCenterCd && this.data.costList.length == 0) {
            this.data.costList.push({
              workPlanId: this.param.workPlanId,  // 작업계획 일련번호
              costCenterCd: this.data.costCenterCd,  // 코스트센터 코드
              costCenterNo: this.data.costCenterNo,  // 코스트센터 코드
              costCenterName: this.data.costCenterName,  // 코스트센터 코드
              calculatePercent: 100,  // 정산율
              calculateCost: '',  // 정산금액
              remark: '',  // 비고
              editFlag: 'C',
            })
          }
          if (!this.data.planUserId) {
            this.data.planUserId = this.$store.getters.user.userId;
          }
          if (this.data.workList && this.data.workList.length > 0) {
            this.$_.forEach(this.data.workList, _item => {
              _item.editFlag = 'C',
              _item.label = `${_item.workPlanWorkName}[${_item.workOprTypeName}]<br/>${_item.workDtsStr}`
              _item.key = uid();
              _item.name = _item.workPlanWorkId;
              _item.component =  () => import(`${'./workOrderPlanService.vue'}`)
              // _item.icon = 'list_alt'; 
            })
          }
          this.$http.url = this.equiplistUrl;
          this.$http.param = {workPlanId: this.data[0].workPlanId};
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid0.data = _result.data;
          },);
          this.selectedWork = true;
          this.setTab();
        },);
      }
    },
  }
};
</script>